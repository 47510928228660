<template>
  <div>
    <Toast />
    <FormSilogtran :service="servicio" v-if="servicio.active == 1"  :loading="loading"   />
    <FormSyscom :order="order" :loading="loading" :button="false" />
    <div class="grid grid-cols-1 gap-12">
      <div class="intro-y box p-5 mt-5">
        <Fieldset legend="Track" :toggleable="true">
          <h5>Conductor: {{ vehiculoItem.NomConductor }}</h5>
          <h5>Placa: {{ vehiculoItem.Placa }}</h5>
          <GoogleMap
              api-key="AIzaSyCsCu2R_oXOLYHt3xBmEEAsPZck8kDHKE4"
              style="width: 100%; height: 500px"
              :center="center"
              :zoom="15"
          >
            <Marker :options="{ position: center }" />
          </GoogleMap>
        </Fieldset>
      </div>
      <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">Orden de cargue</h2>
        <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
          <button class="btn btn-primary shadow-md mr-2" @click="openNewDoc('OCT')">Nuevo</button>
        </div>
      </div>
      <div class="intro-y box p-5 mt-5" v-if="service">
        <DataTable :value="ordenCargue" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                   :rows="10" dataKey="id" :rowHover="true" v-model:selection="selectedServices" :filters="filters" :loading="loading">
          <template #header>
            <div class="table-header">
              Información
              <span class="p-input-icon-left">
							<i class="pi pi-search"/>
							<InputText v-model="filters['global'].value" placeholder="Search"/>
						</span>
            </div>
          </template>

          <template #empty>
            Datos no disponibles.
          </template>
          <template #loading>
            Cargando datos. Por favor espera.
          </template>
          
          <Column field="Numero" header="Número" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Número</span>
              {{slotProps.data.Numero}}
            </template>
          </Column>
          <Column field="Compania" header="Compañia" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Compañia</span>
              {{slotProps.data.Compania}}
            </template>
          </Column>
          <Column field="Placa" header="Placa" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Placa</span>
              {{slotProps.data.Placa}}
            </template>
          </Column>
          <Column field="IdConductor" header="Documento conductor" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Documento conductor</span>
              {{slotProps.data.IdConductor}}
            </template>
          </Column>
          <Column>
            <template #body="slotProps">
              <div class="flex items-center justify-center">
                <a class="flex items-center mr-3 text-theme-10" href="javascript:;" @click="editDoc(slotProps.data)">
                  <CheckSquareIcon class="w-6 h-6 mr-4" />
                </a>
              </div>
            </template>
          </Column>

        </DataTable>
      </div>
      <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">Remesa</h2>
        <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
          <button class="btn btn-primary shadow-md mr-2" @click="openNewDoc('RMT')">Nuevo</button>
        </div>
      </div>
      <div class="card" v-if="service">
        <DataTable :value="remesa" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                   :rows="10" dataKey="id" :rowHover="true" v-model:selection="selectedServices" :filters="filters" :loading="loading">
          <template #header>
            <div class="table-header">
              Información
              <span class="p-input-icon-left">
							<i class="pi pi-search"/>
							<InputText v-model="filters['global'].value" placeholder="Search"/>
						</span>
            </div>
          </template>

          <template #empty>
            Datos no disponibles.
          </template>
          <template #loading>
            Cargando datos. Por favor espera.
          </template>

          <Column field="Numero" header="Número" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Número</span>
              {{slotProps.data.Numero}}
            </template>
          </Column>
          <Column field="Compania" header="Compañia" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Compañia</span>
              {{slotProps.data.Compania}}
            </template>
          </Column>
          <Column field="Placa" header="Placa" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Placa</span>
              {{slotProps.data.Placa}}
            </template>
          </Column>
          <Column field="IdConductor" header="Documento conductor" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Documento conductor</span>
              {{slotProps.data.IdConductor}}
            </template>
          </Column>
          <Column>
            <template #body="slotProps">
              <div class="flex items-center justify-center">
                <a class="flex items-center mr-3 text-theme-10" href="javascript:;" @click="editDoc(slotProps.data)">
                  <CheckSquareIcon class="w-6 h-6 mr-4" />
                </a>
              </div>
            </template>
          </Column>

        </DataTable>
      </div>
      <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">Manifiesto</h2>
        <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
          <button class="btn btn-primary shadow-md mr-2" @click="openNewDoc('MUC')">Nuevo</button>
        </div>
      </div>
      <div class="card" v-if="service">
        <DataTable :value="manifiesto" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                   :rows="10" dataKey="id" :rowHover="true" v-model:selection="selectedServices" :filters="filters" :loading="loading">
          <template #header>
            <div class="table-header">
              Información
              <span class="p-input-icon-left">
							<i class="pi pi-search"/>
							<InputText v-model="filters['global'].value" placeholder="Search"/>
						</span>
            </div>
          </template>

          <template #empty>
            Datos no disponibles.
          </template>
          <template #loading>
            Cargando datos. Por favor espera.
          </template>

          <Column field="Numero" header="Número" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Número</span>
              {{slotProps.data.Numero}}
            </template>
          </Column>
          <Column field="Compania" header="Compañia" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Compañia</span>
              {{slotProps.data.Compania}}
            </template>
          </Column>
          <Column field="Placa" header="Placa" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Placa</span>
              {{slotProps.data.Placa}}
            </template>
          </Column>
          <Column field="IdConductor" header="Documento conductor" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Documento conductor</span>
              {{slotProps.data.IdConductor}}
            </template>
          </Column>
          <Column>
            <template #body="slotProps">
              <div class="flex items-center justify-center">
                <a class="flex items-center mr-3 text-theme-10" href="javascript:;" @click="editDoc(slotProps.data)">
                  <CheckSquareIcon class="w-6 h-6 mr-4" />
                </a>
              </div>
            </template>
          </Column>

        </DataTable>
      </div>
      <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">Novedades</h2>
      </div>
      <div class="card" v-if="service">
        <DataTable :value="service.novedad" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                   :rows="10" dataKey="id" :rowHover="true" v-model:selection="selectedServices" :filters="filters" :loading="loading">
          <template #header>
            <div class="table-header">
              Información
              <span class="p-input-icon-left">
							<i class="pi pi-search"/>
							<InputText v-model="filters['global'].value" placeholder="Search"/>
						</span>
            </div>
          </template>

          <template #empty>
            Datos no disponibles.
          </template>
          <template #loading>
            Cargando datos. Por favor espera.
          </template>

          <Column field="codNovedad" header="Código Novedad" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Código Novedad</span>
              {{slotProps.data.codNovedad}}
            </template>
          </Column>
          <Column field="novedad" header="Novedad" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Novedad</span>
              {{slotProps.data.novedad}}
            </template>
          </Column>

        </DataTable>
      </div>
    </div>
  </div>
  <Dialog v-model:visible="docDialog" :style="{width: '450px'}" header="Documentos" :modal="true" class="p-fluid">
    <div class="p-field">
      <label for="Numero">Numero de consecutivo</label>
      <InputText type="text" id="Numero" v-model.trim="doc.Numero" required="true"  />
    </div>
    <div class="p-field">
      <label for="ClaseVeh">Clase Vehículo</label>
      <Dropdown id="role" v-model="doc.ClaseVeh" :filter="true" :options="optionsClassVeh"  placeholder="Select" />
    </div>
    <div class="p-field">
      <label for="Compania">Compañia</label>
      <InputText type="text" id="Compania" v-model.trim="doc.Compania" required="true"  />
    </div>
    <div class="p-field">
      <label for="Fecha">Fecha</label>
      <InputText type="datetime-local" step="1" id="Fecha" v-model.trim="doc.Fecha" required="true"  />
    </div>
    <div class="p-field">
      <label for="GPSClave">GPSClave</label>
      <InputText type="text" id="GPSClave" v-model.trim="doc.GPSClave" required="true"  />
    </div>
    <div class="p-field">
      <label for="GPSUsuario">GPSUsuario</label>
      <InputText type="text" id="GPSUsuario" v-model.trim="doc.GPSUsuario" required="true"  />
    </div>
    <div class="p-field">
      <label for="GPSoperador">GPSoperador</label>
      <InputText type="text" id="GPSoperador" v-model.trim="doc.GPSoperador" required="true"  />
    </div>
    <div class="p-field">
      <label for="IdConductor">Doc. Conductor</label>
      <InputText type="number" id="IdConductor" v-model.trim="doc.IdConductor" required="true"  />
    </div>
    <div class="p-field">
      <label for="NomConductor">Nombre Conductor</label>
      <InputText type="text" id="NomConductor" v-model.trim="doc.NomConductor" required="true"  />
    </div>
    <div class="p-field">
      <label for="Modelo">Modelo Vehículo</label>
      <InputText type="number" id="Modelo" v-model.trim="doc.Modelo" required="true"  />
    </div>
    <div class="p-field">
      <label for="Placa">Placa</label>
      <InputText type="text" id="Placa" v-model.trim="doc.Placa" required="true"  />
    </div>
    <div class="p-field">
      <label for="Valor">Valor</label>
      <InputText type="text" id="Valor" v-model.trim="doc.Valor" required="true"  />
    </div>
    <div class="p-field">
      <label for="TipoDocumento">Tipo Documento</label>
      <Dropdown id="role" v-model="doc.TipoDocumento" :filter="true" :options="optionsDoc" optionLabel="name" optionValue="id" placeholder="Select" />
    </div>


    <template #footer>
      <button class="btn btn-outline-primary w-24 inline-block mr-1 mb-2" @click="saveDoc">Grabar</button>
    </template>
  </Dialog>

  <Dialog v-model:visible="novedadDialog" :style="{width: '450px'}" header="Novedades" :modal="true" class="p-fluid">
    <div class="p-field">
      <label for="codNovedad">Código Novedad</label>
      <InputText type="number" id="codNovedad" v-model.trim="novedad.codNovedad" required="true"  />
    </div>
    <div class="p-field">
      <label for="novedad">Novedad</label>
      <InputText id="novedad" v-model.trim="novedad.novedad" required="true"  />
    </div>

    <div class="p-field">
      <label for="observacionNov">Observación</label>
      <Textarea id="observacionNov" v-model="novedad.observacion" required="true"  rows="3" cols="20" />
    </div>

    <template #footer>
    </template>
  </Dialog>
</template>
<script>
import AcceptedOrder from '../service/AcceptedOrder';
import {API_LOCAL_URL, API_SATRACK_URL} from "@/config";
import axios from "axios";
import { GoogleMap, Marker } from 'vue3-google-map';
import FormSilogtran from "@/components/forms/FormSilogtran";
import FormSyscom from "@/components/forms/FormSyscom";
import {FilterMatchMode} from "primevue/api";

export default {
  components: { GoogleMap, Marker, FormSilogtran, FormSyscom },
  data() {
    return {
      center: { lat: 4.15, lng: -73.633 },
      filters: {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      },
      doc: {
        "ClaseVeh": null,
        "Compania": null,
        "Fecha": null,
        "GPSClave": null,
        "GPSUsuario": null,
        "GPSoperador": null,
        "IdConductor": null,
        "Modelo": null,
        "NomConductor": null,
        "Numero": null,
        "Placa": null,
        "TipoDocumento": null,
        "Valor": null,
        "solicitud": null
      },
      docDialog: false,
      optionsClassVeh: [
        'TURBO',
        'SENCILLO',
        'DOBLE TROQUE',
        'RIGIDO 4 EJES',
        'MINI MULA 2S2',
        'TRACTOCAMION 2 TROQUES',
        'TRACTOCAMION 3 TROQUES',
        'CAMIONETA',
        'AUTOMOVIL',
        'CAMIONETA DE 2 EJES',
        'VOLQUETA DE 2 EJES',
        'VOLQUETA DE 3 EJES',
        'CARGA'
      ],
      optionsDoc: [
        {
          "id": "MUC",
          "name": "Manifiesto",
        },
        {
          "id": "OCT",
          "name": "Orden de cargue",
        },
        {
          "id": "RMT",
          "name": "Remesa",
        },
        {
          "id": "CUM",
          "name": "Cumplido",
        },
        {
          "id": "FCR",
          "name": "Factura",
        },
      ],

      service: {},
      servicio: {},
      selectedServices: null,
      submitted: false,
      novedadDialog: false,
      novedad: {
        'codNovedad': null,
        'novedad': null,
        'observacion': null,
        'solicitud': null
      },
      loading: true,
      vehiculoItem: {
        'ClaseVeh':null,
        'GPSClave':null,
        'GPSUsuario':null,
        'GPSoperador':null,
        'IdConductor':null,
        'NomConductor':null,
        'Placa':null,
        'Modelo':null,
        "solicitud":null,
      },
      optionsCdRango: [],
      optionsEmbalajes: [],
      optionsIdEmpaque: [],
      optionsIdManejo: [],
      optionsIdMercancia: [],
      optionsIdNaturaleza: [],
      optionsUndMed: [],
      optionsRiesgos: [
        "PERECEDERO",
        "INFLAMABLE",
        "TOXICO",
        "OXIDANTE",
        "EXPLOSIVO",
        "VENENOSO",
        "RADIOACTIVA"
      ],
      detalleItem: {
        "Cajas":null,
        "Cantidad":null,
        "Cases":null,
        "CdRango":null,
        "CiudadDestino":null,
        "CiudadOrigen":null,
        "Contenedor1":null,
        "Contenedor2":null,
        "DescripMcias":null,
        "DirDestino":null,
        "DirOrigen":null,
        "DocCliente":null,
        "Embalajes":null,
        "IdEmpaque":null,
        "IdManejo":null,
        "IdMercancia":null,
        "IdNaturaleza":null,
        "NitDestinatario":null,
        "NitRemitente":null,
        "Palets":null,
        "Peso":null,
        "Referencia1":null,
        "Referencia2":null,
        "Referencia3":null,
        "Riesgos":null,
        "SedeDestinatario":null,
        "SedeRemitente":null,
        "TarifSeguro":null,
        "TarifaCliente":null,
        "TarifaPago":null,
        "TarifaTabla":null,
        "Tipo_Servicio":null,
        "UndMed":null,
        "UndTarifaPago":null,
        "UndVol":null,
        "UnidadCliente":null,
        "Volumen":null,
        "VrDeclarado":null,
        "dmsAlto":null,
        "dmsAncho":null,
        "dmsLargo":null,
        "solicitud":null
      },
      conceptoItem:{
        'Cantidad':null,
        'CodigoConcepto':null,
        'Descripcion':null,
        'Nit':null,
        'Rubro':null,
        'TipoConcepto':null,
        'TipoEscolta':null,
        'VrUnitario':null,
        'solicitud':null
      },
      optionsCdTipCarga: [],
      optionsCdTipoEsc: [],
      optionsCdTipoVehic: [],
      optionsMoneda: [],
      optionsIdVend: [],
      optionsEmbarque: [
        "SUELTA",
        "CONTENEDOR",
        "CUPO",
        "OTRO"
      ],
      optionsModalidad: [
        "ESTANDAR",
        "ESPECIFICO"
      ],
      optionsSeguros: [
        "EMPRESA",
        "CLIENTE"
      ],
      optionsTipoMargen: [
        "UNIDAD",
        "PORCENTAJE(%)"
      ],
      optionsTipoRuta: [
        "NACIONAL",
        "LOCAL"
      ],
      optionsTipoTarifa: [
        "PEDIDO",
        "TABLA",
        "REMESA",
        "CUMPLIDO"
      ],
      optionsTipoTransporte: [
        "GENERAL",
        "PAQUETEO",
        "CONTENEDOR",
        "VARIOSDIA"
      ],
      optionsUndCalcFalt: [
        "PESO",
        "UNIDADES"
      ],
      order: {
        Concepto: [],
        Detalle: [],
        Encabezado: {
          "Cargue":null,
          "CdTipCarga":null,
          "CdTipoEsc":null,
          "CdTipoVehic":null,
          "CiudadDevContenedor":null,
          "ContactoSIA":null,
          "Descargue":null,
          "DevContenedor":false,
          "DiasVigencia":null,
          "Embarque":null,
          "FecDespacho":null,
          "FecEntrega":null,
          "FecVencePol":null,
          "Fecha":null,
          "IdAgencia":null,
          "IdRuta":null,
          "IdVend":null,
          "MargenFalt":null,
          "Modalidad":null,
          "Moneda":null,
          "NitCiaPoliza":null,
          "NitCliente":null,
          "nombre_cliente":null,
          "NitSIA":null,
          "NomContacto":null,
          "NomContactoDest":null,
          "NumPolizaEsp":null,
          "Observacion":null,
          "PatioCont":null,
          "PolizaEspecifica":false,
          "Seguros":null,
          "TarifFaltCobro":null,
          "TarifFaltPago":null,
          "TarifaComision":null,
          "TelContacto":null,
          "TelContactoDest":null,
          "TeleContactoSIA":null,
          "TipoMargen":null,
          "TipoRuta":null,
          "TipoTarifa":null,
          "TipoTransporte":null,
          "UndCalcFalt":null,
          "Vigencia":null,
          "VrCargos":null,
          "VrCargue":null,
          "VrDctos":null,
          "VrDesCargue":null,
          "VrDevolucionContdor":null,
          "VrEmbalajes":null,
          "VrEscolta":null,
          "VrLimiteDesp":null,
          "VrTasa":null,
          "VrTraUrbano":null,
          "emailContac":null,
          "emailContacDest":null,
          info_trazabilidad: [],
          NomCliente: null,
          FacturarA: null,
          NomRemitente: null,
          NomDestinatario: null,
          //Search
          IdClie: null,
          IdTercero: null,
          IdTercero1: null,
          IdTercero2: null,
          NomCiaPoliza: null,
          IdTercero3: null,
          NomNit: null,
          CodRuta: null,
          NomRuta: null
        }
      },
      optionsTipoEscolta: [
        {
          code: "0003",
          name: "EN CABINA"
        },
        {
          code: "0002",
          name: "MOTO"
        },
        {
          code: "0001",
          name: "VEHICULAR"
        }
      ],
      optionsUndVol: [
        {
          code: "m3",
          name: "METRO CÚBICO"
        }
      ],
      searchInputIdMercancia: '',
      optionsCiudad: [],
      searchInputCiudad: '',
      optionsTercero: [],
      searchInputTercero: '',
      optionsIdRuta: [],
      searchInputIdRuta: '',
      optionsIdAgencia: [],
      searchInputIdAgencia: ''
    }
  },
  AcceptedOrder: null,
  created() {
    this.AcceptedOrder = new AcceptedOrder();
    let recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', 'assets/js/methods.js')
    document.head.appendChild(recaptchaScript)
  },
  computed: {
    ordenCargue: function () {
      return this.order.Encabezado.info_trazabilidad.filter(val => val.TipoDocumento == 'OCT');
    },
    remesa: function () {
      return this.order.Encabezado.info_trazabilidad.filter(val => val.TipoDocumento == 'RMT');
    },
    manifiesto: function () {
      return this.order.Encabezado.info_trazabilidad.filter(val => val.TipoDocumento == 'MUC');
    }
  },
  mounted() {
    if(this.$route.params.id) {

      //this.doc.solicitud = this.$route.params.id;

      this.AcceptedOrder.getDocuments(this.$route.params.id).then(data => {
        this.$toast.add({
          severity:'success',
          summary: data.message,
          detail: 'Documentos actualizados.',
          life: 5000
        });
      })
          .catch(() => {
            this.$toast.add({
              severity: 'error',
              summary: 'Error al traer los datos',
              detail: 'Error al traer los datos',
              life: 5000
            });
          });

      this.AcceptedOrder.getServiceOrder(this.$route.params.id).then(data => {

        this.order.Encabezado = JSON.parse(JSON.stringify(data));
        this.order.Detalle = JSON.parse(JSON.stringify(data.detalle));
        this.order.Concepto = JSON.parse(JSON.stringify(data.concepto));

        this.tipoServicio = this.order.Encabezado.tipo_servicio;
        this.solicitudServicio = this.order.Encabezado.solicitud.solicitudServicio;
        this.order.Encabezado.IdClie = this.order.Encabezado.NitCliente.IdClie;
        this.order.Encabezado.NomCliente = this.order.Encabezado.NitCliente.NomRepLeg2;
        this.order.Encabezado.nombre_cliente = this.order.Encabezado.NitCliente.NomRepLeg2;
        this.order.Encabezado.FacturarA = this.order.Encabezado.NitCliente.RazonSocialFact;
        if (this.order.Encabezado.NitCliente.RazonSocialFact !== null) {
          this.order.Encabezado.FacturarA = [this.order.Encabezado.NitCliente.RazonSocialFact.IdTercero, this.order.Encabezado.NitCliente.RazonSocialFact.RazonSocial].join(' ');
        }
        if (this.order.Encabezado.NitCiaPoliza !== null) {
          this.order.Encabezado.IdTercero2 = this.order.Encabezado.NitCiaPoliza.IdTercero;
          this.order.Encabezado.NomCiaPoliza = this.order.Encabezado.NitCiaPoliza.NomRepLeg2;
        }
        this.order.Encabezado.CodRuta = this.order.Encabezado.IdRuta.IdRuta;
        this.order.Encabezado.NomRuta = this.order.Encabezado.IdRuta.Ruta;

        for (const i in this.order.Detalle) {
          this.order.Encabezado.IdTercero = this.order.Detalle[i].NitRemitente.IdTercero;
          this.order.Encabezado.NomRemitente = this.order.Detalle[i].NitRemitente.RazonSocial2;
          this.order.Encabezado.IdTercero1 = this.order.Detalle[i].NitDestinatario.IdTercero;
          this.order.Encabezado.NomDestinatario = this.order.Detalle[i].NitDestinatario.RazonSocial2;
          for (const j in this.optionsUndVol) {
            if (this.optionsUndVol[j].code == this.order.Detalle[i].UndVol) {
              this.order.Detalle[i].UndVol = this.optionsUndVol[j];
            }
          }
        }

        for (const i in this.order.Concepto) {
          if (this.order.Concepto[i].Nit !== null) {
            this.order.Encabezado.IdTercero3 = this.order.Concepto[i].Nit.IdTercero;
            this.order.Encabezado.NomNit = this.order.Concepto[i].Nit.RazonSocial2;
          }
          for (const j in this.optionsTipoEscolta) {
            if (this.optionsTipoEscolta[j].code == this.order.Concepto[i].TipoEscolta) {
              this.order.Concepto[i].TipoEscolta = this.optionsTipoEscolta[j];
            }
          }
        }

        this.order.Encabezado.PolizaEspecifica =  (this.order.Encabezado.PolizaEspecifica == 1) ? true : false;
        this.order.Encabezado.DevContenedor =  (this.order.Encabezado.DevContenedor == 1) ? true : false;
        if (this.order.Encabezado.FecDespacho !== null) {
          this.order.Encabezado.FecDespacho = this.order.Encabezado.FecDespacho.replace(" ", "T");
        }
        if (this.order.Encabezado.FecEntrega !== null) {
          this.order.Encabezado.FecEntrega = this.order.Encabezado.FecEntrega.replace(" ", "T");
        }
        if (this.order.Encabezado.FecVencePol !== null) {
          this.order.Encabezado.FecVencePol = this.order.Encabezado.FecVencePol.replace(" ", "T");
        }
        if (this.order.Encabezado.Fecha !== null) {
          this.order.Encabezado.Fecha = this.order.Encabezado.Fecha.replace(" ", "T");
        }

        if(data.vehiculo.length > 0)
          this.vehiculoItem = JSON.parse(JSON.stringify(data.vehiculo[0]));

        this.service = JSON.parse(JSON.stringify(data));

        this.AcceptedOrder.getOrder(this.service.solicitud.id).then(data => {

          this.servicio = data;
          if (this.servicio.departamentoOrigenCodigoDane != null)
            this.servicio.departamentoOrigenCodigoDane = this.servicio.departamentoOrigenCodigoDane.id;
          if (this.servicio.departamentoDestinoCodigoDane != null)
            this.servicio.departamentoDestinoCodigoDane = this.servicio.departamentoDestinoCodigoDane.id;

          if (this.servicio.pesoUnidadMedida != null)
            this.servicio.pesoUnidadMedida = this.servicio.pesoUnidadMedida.nombre;

          if (this.servicio.volumenUnidadMedida != null)
            this.servicio.volumenUnidadMedida = this.servicio.volumenUnidadMedida.nombre;

          for (const j in this.servicio.info_entregas) {

            for (const i in this.servicio.info_entregas[j].mercancia) {

              if (this.servicio.info_entregas[j].mercancia[i].dimensionesUnidadMedida != null)
                this.servicio.info_entregas[j].mercancia[i].dimensionesUnidadMedida = this.servicio.info_entregas[j].mercancia[i].dimensionesUnidadMedida.nombre;
              if (this.servicio.info_entregas[j].mercancia[i].pesoUnidadMedida != null)
                this.servicio.info_entregas[j].mercancia[i].pesoUnidadMedida = this.servicio.info_entregas[j].mercancia[i].pesoUnidadMedida.nombre;
              if (this.servicio.info_entregas[j].mercancia[i].volumenUnidadMedida != null)
                this.servicio.info_entregas[j].mercancia[i].volumenUnidadMedida = this.servicio.info_entregas[j].mercancia[i].volumenUnidadMedida.nombre;
              if (this.servicio.info_entregas[j].mercancia[i].valorArancelIvaMoneda != null)
                this.servicio.info_entregas[j].mercancia[i].valorArancelIvaMoneda = this.servicio.info_entregas[j].mercancia[i].valorArancelIvaMoneda.nombre;
              if (this.servicio.info_entregas[j].mercancia[i].valorMercanciaMoneda != null)
                this.servicio.info_entregas[j].mercancia[i].valorMercanciaMoneda = this.servicio.info_entregas[j].mercancia[i].valorMercanciaMoneda.nombre;

            }

            for (const i in this.servicio.info_entregas[j].contenedor) {

              if (this.servicio.info_entregas[j].contenedor[i].contenedorTipo != null)
                this.servicio.info_entregas[j].contenedor[i].contenedorTipo = this.servicio.info_entregas[j].contenedor[i].contenedorTipo.nombre;
              if (this.servicio.info_entregas[j].contenedor[i].contenedorTipoAbreviatura != null)
                this.servicio.info_entregas[j].contenedor[i].contenedorTipoAbreviatura = this.servicio.info_entregas[j].contenedor[i].contenedorTipoAbreviatura.codigo;
              if (this.servicio.info_entregas[j].contenedor[i].pesoUnidadMedida != null)
                this.servicio.info_entregas[j].contenedor[i].pesoUnidadMedida = this.servicio.info_entregas[j].contenedor[i].pesoUnidadMedida.nombre;
              if (this.servicio.info_entregas[j].contenedor[i].valorMercanciaMoneda != null)
                this.servicio.info_entregas[j].contenedor[i].valorMercanciaMoneda = this.servicio.info_entregas[j].contenedor[i].valorMercanciaMoneda.nombre;
              if (this.servicio.info_entregas[j].contenedor[i].valorArancelIvaMoneda != null)
                this.servicio.info_entregas[j].contenedor[i].valorArancelIvaMoneda = this.servicio.info_entregas[j].contenedor[i].valorArancelIvaMoneda.nombre;


            }

            for (const i in this.servicio.info_entregas[j].servicioAdicional) {

              if (this.servicio.info_entregas[j].servicioAdicional[i].servicioAdicional != null)
                this.servicio.info_entregas[j].servicioAdicional[i].servicioAdicional = this.servicio.info_entregas[j].servicioAdicional[i].servicioAdicional.nombre;
              if (this.servicio.info_entregas[j].servicioAdicional[i].valorTotalMoneda != null)
                this.servicio.info_entregas[j].servicioAdicional[i].valorTotalMoneda = this.servicio.info_entregas[j].servicioAdicional[i].valorTotalMoneda.nombre;

            }

          }

        })
            .catch(() => {
              this.$toast.add({
                severity: 'error',
                summary: 'Error al traer los datos',
                detail: 'Error al traer los datos',
                life: 5000
              });
            });


        if (this.vehiculoItem.GPSoperador === 'SATRACK' || this.vehiculoItem.GPSoperador === 'satrack') {

            var postData = '<Envelope xmlns="http://schemas.xmlsoap.org/soap/envelope/">\n' +
                '    <Body>\n' +
                '        <getLastEvent xmlns="http://tempuri.org/">\n' +
                '            <UserName>' + this.vehiculoItem.GPSUsuario + '</UserName>\n' +
                '            <Password>' + this.vehiculoItem.GPSClave + '</Password>\n' +
                '            <PhysicalID>' + this.vehiculoItem.Placa + '</PhysicalID>\n' +
                '        </getLastEvent>\n' +
                '    </Body>\n' +
                '</Envelope>';

            this.postApiSatrack('getLastEvent', postData)
                .then(response => {
                  this.center = { lat: parseFloat(response.data.LastEvents.Latitud), lng: parseFloat(response.data.LastEvents.Longitud) };
                  this.postApiLocal('lastevent', response.data.LastEvents)
                      .catch(() => {
                        this.$toast.add({
                          severity: 'error',
                          summary: 'Error de solicitud',
                          detail: 'No se ha podido procesar la solicitud',
                          life: 5000
                        });
                      });
                })
                .catch(() => {
                  this.$toast.add({
                    severity: 'error',
                    summary: 'Error de solicitud',
                    detail: 'No se ha podido procesar la solicitud',
                    life: 5000
                  });
                });

          }

        this.loading = false;
      })
          .catch(() => {
            this.$toast.add({
              severity: 'error',
              summary: 'Error al traer los datos',
              detail: 'Error al traer los datos',
              life: 5000
            });
            this.loading = false;
          });
    }else{
      this.order.Detalle.push(JSON.parse(JSON.stringify(this.detalleItem)));
    }

    this.AcceptedOrder.getSelects('rango').then(data => {
      this.optionsCdRango = data;
    });

    this.AcceptedOrder.getSelects('embalajesyscom').then(data => {
      this.optionsEmbalajes = data;
    });

    this.AcceptedOrder.getSelects('empaque').then(data => {
      this.optionsIdEmpaque = data;
    });

    this.AcceptedOrder.getSelects('manejo').then(data => {
      this.optionsIdManejo = data;
    });

    this.AcceptedOrder.getSelects('naturaleza').then(data => {
      this.optionsIdNaturaleza = data;
    });

    this.AcceptedOrder.getSelects('undmedsyscom').then(data => {
      this.optionsUndMed = data;
    });

    this.AcceptedOrder.getSelects('carga').then(data => {
      this.optionsCdTipCarga = data;
    });

    this.AcceptedOrder.getSelects('tipoescolta').then(data => {
      this.optionsCdTipoEsc = data;
    });

    this.AcceptedOrder.getSelects('tipovehiculo').then(data => {
      this.optionsCdTipoVehic = data;
    });

    this.AcceptedOrder.getSelects('moneda').then(data => {
      this.optionsMoneda = data;
    });

    this.AcceptedOrder.getSelects('vendedor').then(data => {
      this.optionsIdVend = data;
    });
  },
  methods: {
    findIndexById(solicitud, doc, num) {
      let index = -1;
      for (let i = 0; i < this.order.Encabezado.info_trazabilidad.length; i++) {
        if (this.order.Encabezado.info_trazabilidad[i].solicitud === solicitud
        && this.order.Encabezado.info_trazabilidad[i].TipoDocumento === doc
        && this.order.Encabezado.info_trazabilidad[i].Numero === num) {
          index = i;
          break;
        }
      }
      return index;
    },
    openNewDoc(TipoDocumento) {
      Object.assign(this.$data.doc, this.$options.data().doc);
      this.doc.TipoDocumento = TipoDocumento;
      this.submitted = false;
      this.docDialog = true;
    },
    async saveDoc() {
      this.submitted = true;
      this.docDialog = false;
      this.loading = true;

      var axios1, method = 'post', slug = '';

      if (this.doc.solicitud) {
        method = 'put';
        slug = '/'+this.doc.solicitud;
      }

      let FechaSyscom = new Date(this.doc.Fecha);
      this.doc.Fecha = '/Date('+FechaSyscom.getTime()+'-0500)/';
      if (this.doc.solicitud === "" || this.doc.solicitud === null) {
        this.doc.solicitud = this.$route.params.id;
      }

      axios1 = await new Promise((resolve)=>{
        this.sendApiLocal('trazabilidadsyscom'+slug, this.doc, method)
            .then(response => {
              resolve(response);
            })
            .catch(() => {
              this.$toast.add({severity:'error', summary: 'Error de solicitud', detail:'No se ha podido procesar la solicitud', life: 5000});
              this.loading = false;
            });
      });

      Promise.all([axios1]).then(() => {
        if (method == 'put') {
          this.order.Encabezado.info_trazabilidad[this.findIndexById(axios1.data.data.solicitud, axios1.data.data.TipoDocumento, axios1.data.data.Numero)] = axios1.data.data;
          this.$toast.add({severity:'success', summary: 'Exito!', detail: 'Editado correctamente', life: 5000});
        }
        else {
          this.order.Encabezado.info_trazabilidad.push(JSON.parse(JSON.stringify(axios1.data.data)));
          this.$toast.add({severity:'success', summary: 'Exito!', detail: 'Creado correctamente', life: 5000});
        }
        this.loading = false;
      });
    },
    editDoc(data) {
      this.doc.ClaseVeh = data.ClaseVeh;
      this.doc.Compania = data.Compania;
      let Fecha1 = data.Fecha.replace("/Date(", "");
      let Fecha2 = parseInt(Fecha1.replace("-0500)/", ""));
      let Fecha3 = new Date(Fecha2);
      this.doc.Fecha = Fecha3.toISOString().split(".")[0];
      this.doc.GPSClave = data.GPSClave;
      this.doc.GPSUsuario = data.GPSUsuario;
      this.doc.GPSoperador = data.GPSoperador;
      this.doc.IdConductor = data.IdConductor;
      this.doc.Modelo = data.Modelo;
      this.doc.NomConductor = data.NomConductor;
      this.doc.Numero = data.Numero;
      this.doc.Placa = data.Placa;
      this.doc.Valor = data.Valor;
      this.doc.TipoDocumento = data.TipoDocumento;
      this.doc.solicitud = data.solicitud;
      this.submitted = false;
      this.docDialog = true;
    },
    sendApiLocal(slug, data, method){
      const config = {
        headers: {
          "Authorization": "Bearer " + this.$store.state.auth.token,
          "Content-Type": "application/json",
          "Accept": "application/json",
        }
      };
      if (method == 'post') {
        return axios.post(API_LOCAL_URL + slug, data, config);
      } else if (method == 'delete'){
        return axios.delete(API_LOCAL_URL + slug, config);
      } else {
        return axios.put(API_LOCAL_URL+slug, data, config);
      }
    },
    openNewNovedad() {
      Object.assign(this.$data.novedad, this.$options.data().novedad);
      this.submitted = false;
      this.novedadDialog = true;
    },
    async saveNovedad() {
      this.submitted = true;
      this.novedadDialog = false;
      this.loading = true;

      this.novedad.solicitud = this.service.id;

      let axios1;

      axios1 = await new Promise((resolve)=>{
        this.postApiLocal('novedadsyscom', this.novedad)
            .then(() => {
              resolve(resolve);
            })
            .catch(() => {
              this.$toast.add({severity:'error', summary: 'Error de solicitud', detail:'No se ha podido procesar la solicitud', life: 5000});
              this.loading = false;
            });
      });

      Promise.all([axios1]).then(() => {
        //this.services[this.findIndexById(this.service.id)].novedad.push(JSON.parse(JSON.stringify(this.novedad)));
        this.service.novedad.push(JSON.parse(JSON.stringify(this.novedad)));
        this.loading = false;
        this.$toast.add({severity:'success', summary: 'Exito', detail: 'Datos guardados correctamente.', life: 5000});
      });
    },
    postApiSatrack(method, data){
      const config = {
        headers: {
          "Content-Type": "application/xml",
          "Accept": "application/xml",
        }
      };
      let url = API_SATRACK_URL.replace('{replace}', 'method='+method+'&');
      return axios
          .post(url, data, config);
    },
    postApiLocal(slug, data){
      const config = {
        headers: {
          "Authorization": "Bearer " + this.$store.state.auth.token,
          "Content-Type": "application/json",
          "Accept": "application/json",
        }
      };
      return axios
          .post(API_LOCAL_URL+slug, data, config);
    },
    async hanldeSearchInputIdMercancia(event) {
      this.searchInputIdMercancia = event.query.trim();
      this.optionsIdMercancia = await this.AcceptedOrder.getSearchSelects('tipomercancia', this.searchInputIdMercancia).then(data => {
        return data;
      })
          .catch(() => {
            this.$toast.add({
              severity: 'error',
              summary: 'Error al traer los datos',
              detail: 'Error al traer los datos (3)',
              life: 5000
            });
            return [];
          });
    },
    async hanldeSearchInputCiudad(event) {
      this.searchInputCiudad = event.query.trim();

      this.optionsCiudad = await this.AcceptedOrder.getSearchSelects('ciudad', this.searchInputCiudad).then(data => {
        return data;
      })
          .catch(() => {
            this.$toast.add({
              severity: 'error',
              summary: 'Error al traer los datos',
              detail: 'Error al traer los datos (4)',
              life: 5000
            });
            return [];
          });
    },
    async hanldeSearchInputTercero(event) {
      this.searchInputTercero = event.query.trim();

      this.optionsTercero = await this.AcceptedOrder.getSearchSelects('tercerosyscom', this.searchInputTercero).then(data => {
        return data;
      })
          .catch(() => {
            this.$toast.add({
              severity: 'error',
              summary: 'Error al traer los datos',
              detail: 'Error al traer los datos (5)',
              life: 5000
            });
            return [];
          });
    },
    async hanldeSearchInputIdRuta(event) {
      this.searchInputIdRuta = event.query.trim();

      this.optionsIdRuta = await this.AcceptedOrder.getSearchSelects('rutasyscom', this.searchInputIdRuta).then(data => {
        return data;
      })
          .catch(() => {
            this.$toast.add({
              severity: 'error',
              summary: 'Error al traer los datos',
              detail: 'Error al traer los datos (6)',
              life: 5000
            });
            return [];
          });
    },
    async hanldeSearchInputIdAgencia(event) {
      this.searchInputIdAgencia = event.query.trim();

      this.optionsIdAgencia = await this.AcceptedOrder.getSearchSelects('agencia', this.searchInputIdAgencia).then(data => {
        return data;
      })
          .catch(() => {
            this.$toast.add({
              severity: 'error',
              summary: 'Error al traer los datos',
              detail: 'Error al traer los datos (7)',
              life: 5000
            });
            return [];
          });
    },
  }
}
</script>
<style scoped lang="scss">
::v-deep(.p-progressbar) {
  height: .5rem;
  background-color: #D8DADC;

  .p-progressbar-value {
    background-color: #607D8B;
  }
}

.p-datatable .p-column-filter {
  display: none;
}

.table-header {
  display: flex;
  justify-content: space-between;
}

::v-deep(.p-datatable.p-datatable-customers) {
  .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 1rem;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
}

/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

.customer-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;

  &.status-qualified {
    background: #C8E6C9;
    color: #256029;
  }

  &.status-unqualified {
    background: #FFCDD2;
    color: #C63737;
  }

  &.status-negotiation {
    background: #FEEDAF;
    color: #8A5340;
  }

  &.status-new {
    background: #B3E5FC;
    color: #23547B;
  }

  &.status-renewal {
    background: #ECCFFF;
    color: #694382;
  }

  &.status-proposal {
    background: #FFD8B2;
    color: #805B36;
  }
}

.p-progressbar-value.ui-widget-header {
  background: #607d8b;
}

@media (max-width: 640px) {
  .p-progressbar {
    margin-top: .5rem;
  }
}

.product-image {
  width: 100px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
}

.orders-subtable {
  padding: 1rem;
}

.product-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;

  &.status-instock {
    background: #C8E6C9;
    color: #256029;
  }

  &.status-outofstock {
    background: #FFCDD2;
    color: #C63737;
  }

  &.status-lowstock {
    background: #FEEDAF;
    color: #8A5340;
  }
}

.order-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;

  &.order-delivered {
    background: #C8E6C9;
    color: #256029;
  }

  &.order-cancelled {
    background: #FFCDD2;
    color: #C63737;
  }

  &.order-pending {
    background: #FEEDAF;
    color: #8A5340;
  }

  &.order-returned {
    background: #ECCFFF;
    color: #694382;
  }
}

@media screen and (max-width: 960px) {
  ::v-deep(.p-datatable) {
    &.p-datatable-customers {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;
      }

      .p-datatable-tbody > tr {
        > td {
          text-align: left;
          display: block;
          border: 0 none !important;
          width: 100% !important;
          float: left;
          clear: left;
          border: 0 none;

          .p-column-title {
            padding: .4rem;
            min-width: 30%;
            display: inline-block;
            margin: -.4rem 1rem -.4rem -.4rem;
            font-weight: bold;
          }

          .p-progressbar {
            margin-top: .5rem;
          }
        }
      }
    }
  }
}
</style>
